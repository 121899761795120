export const MessageAction = {
    MESSAGE: 'INFO_MESSAGE',
};

export const VerseAction = {
    ADD: 'ADD_VERSE',
    SET_CURRENTLY_DISPLAYED: 'SET_CURRENTLY_DISPLAYED_VERSE',
    INCREASE_CURRENTLY_DISPLAYED: 'INCREASE_CURRENTLY_DISPLAYED',
    DECREASE_CURRENTLY_DISPLAYED: 'DECREASE_CURRENTLY_DISPLAYED',
    UPDATE_IMAGE: 'UPDATE_IMAGE',
    INSERT_RANDOM_NATURE_PICTURE: 'INSERT_RANDOM_NATURE_PICTURE',
    SET_RANDOM_IMAGE_LIST: 'SET_RANDOM_IMAGE_LIST',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    SET_LATEST_DOWNLOADED_VERSE: 'SET_LATEST_DOWNLOADED_VERSE',
};

export const EmailAction = {
    OPEN_DIALOG: 'OPEN_EMAIL_DIALOG',
    CLOSE_DIALOG: 'CLOSE_EMAIL_DIALOG',
    UPDATE_EMAIL: 'UPDATE_EMAIL',
    SHOW_EMAIL_ERROR: 'SHOW_EMAIL_ERROR',
    HIDE_EMAIL_ERROR: 'HIDE_EMAIL_ERROR',
};

export const WebappAction = {
    SUBSCRIBE_USER: 'SUBSCRIBE_USER',
    UPDATE_USER: 'UPDATE_USER',
    DELETE_USER: 'DELETE_USER',
};

export const TranslationAction = {
    OPEN_DIALOG: 'OPEN_TRANSLATION_DIALOG',
    CLOSE_DIALOG: 'CLOSE_TRANSLATION_DIALOG',
    CHANGE_TRANSLATION: 'CHANGE_TRANSLATION',
    OTHER_TRANSLATIONS_LOADED: 'OTHER_TRANSLATIONS_LOADED',
};

export const UnsubscribeAction = {
    OPEN_DIALOG: 'OPEN_UNSUBSCRIBE_DIALOG',
    CLOSE_DIALOG: 'CLOSE_UNSUBSCRIBE_DIALOG',
};
