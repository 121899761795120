import {connect} from 'react-redux';
import Carousel from "../presentational/Carousel";
import {decreaseCurrentlyDisplayedVerse, getVersesAsync, increaseCurrentlyDisplayedVerse} from "../../actions";
import {getDateObject, shouldLoadNewVerses} from "../../utils/Utils";
import {getCurrentlyDisplayedVerseDate, getLatestDownloadedVerse} from "../../selectors";

const mapStateToProps = state => ({
    currentlyDisplayed: getCurrentlyDisplayedVerseDate(state),
    latestDownloaded: getLatestDownloadedVerse(state),
});

const mapDispatchToProps = dispatch => ({
    increase: () => {
        dispatch(increaseCurrentlyDisplayedVerse());
    },
    decrease: (currentlyDisplayed, latestDownloaded) => {
        if(shouldLoadNewVerses(currentlyDisplayed, latestDownloaded)) {
            dispatch(getVersesAsync(getDateObject(latestDownloaded)));
        }
        dispatch(decreaseCurrentlyDisplayedVerse());
    },
});

const CarouselContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(Carousel);

export default CarouselContainer;
