import axios from 'axios';
import {
    EmailAction,
    MessageAction,
    TranslationAction,
    UnsubscribeAction,
    VerseAction,
    WebappAction
} from "./action-types";
import {addDays, decreaseDate, getDbDate, increaseDate, validateEmail} from "./utils/Utils";
import {CookieConst, NotificationMessage, UnsubscribeDialogConst, VerseRequestConst} from "./constants";
import {
    getCurrentLanguage,
    getCurrentlyDisplayedVerseDate,
    getEmail,
    getLongTranslation,
    getOtherTranslations,
    getTranslation,
    getVerses,
    getWebappSubscription
} from "./selectors";

axios.defaults.baseURL = 'https://dailypower.oemel09.de/';

export const showMessage = message => ({
    type: MessageAction.MESSAGE,
    open: true,
    message,
});
export const hideMessage = () => ({
    type: MessageAction.MESSAGE,
    open: false,
    message: '',
});

export const updateImage = (date) => ({
    type: VerseAction.UPDATE_IMAGE,
    date,
});

export const setCurrentlyDisplayedVerse = (date) => ({
    type: VerseAction.SET_CURRENTLY_DISPLAYED,
    date,
});

export const increaseCurrentlyDisplayedVerse = () =>
    (dispatch, getState) => dispatch(setCurrentlyDisplayedVerse(
        increaseDate(getCurrentlyDisplayedVerseDate(getState()))));

export const decreaseCurrentlyDisplayedVerse = () =>
    (dispatch, getState) => dispatch(setCurrentlyDisplayedVerse(
        decreaseDate(getCurrentlyDisplayedVerseDate(getState()))));

export const addVerses = (verses) => ({
    type: VerseAction.ADD,
    verses,
});

export const getVersesAsync = (date) =>
    (dispatch, getState) => {
        const dateQuery = 'before=' + getDbDate(date);
        const limitQuery = 'limit=' + VerseRequestConst.VERSE_REQUEST_LIMIT;
        const languageQuery = 'lang=' + getCurrentLanguage(getState());
        return axios.get('/api/v1/verses/?' + dateQuery + '&' + limitQuery + '&' + languageQuery)
            .then((response) => {
                const isValidRequest = isVerseRequestValid(date, response);
                setLatestVerseRequestCookie(isValidRequest, date);
                dispatch(addVerses(response.data));
                if (!isValidRequest) {
                    dispatch(setLatestDownloadedVerse(getDbDate(addDays(date, -VerseRequestConst.VERSE_REQUEST_LIMIT))));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

const isVerseRequestValid = (date, response) => new Date(getDbDate(addDays(date, -1))).toDateString() === new Date(response.data[0].date).toDateString();

const setLatestVerseRequestCookie = (isValidRequest, date) => {
    if (isValidRequest) {
        setCookie(CookieConst.LATEST_VERSE_REQUEST_DATE, date, 365);
    } else {
        setCookie(CookieConst.LATEST_VERSE_REQUEST_DATE, addDays(date, -VerseRequestConst.VERSE_REQUEST_LIMIT), 365);
    }
};

export const setLatestDownloadedVerse = (date) => ({
    type: VerseAction.SET_LATEST_DOWNLOADED_VERSE,
    date,
});


export const setLanguage = lang => ({
    type: VerseAction.CHANGE_LANGUAGE,
    lang,
});

export const changeLanguage = (lang, longTranslation) => (
    dispatch => {
        dispatch(setLanguage(lang));

        const date = new Date();
        date.setHours(0, 0, 0, 0);
        dispatch(getVersesAsync(addDays(date, 1)));
        dispatch(setCurrentlyDisplayedVerse(getDbDate(date)));
        dispatch(updateTranslation(lang, longTranslation));

        setCookie(CookieConst.TRANSLATION, lang, 365);
        setCookie(CookieConst.TRANSLATION_LONG, longTranslation, 365);
    }
);

export const insertRandomNaturePicture = (verseDate) => ({
    type: VerseAction.INSERT_RANDOM_NATURE_PICTURE,
    verseDate,
});

export const setRandomImageList = (urls) => ({
    type: VerseAction.SET_RANDOM_IMAGE_LIST,
    urls,
});

export const randomImageListLoaded = (urls) => (
    (dispatch, getState) => {
        dispatch(setRandomImageList(urls));
        const state = getState();
        const verses = getVerses(state);
        const currentlyDisplayed = getCurrentlyDisplayedVerseDate(state);
        if (typeof verses[currentlyDisplayed] !== 'undefined'
            && verses[currentlyDisplayed].image === null) {
            dispatch(insertRandomNaturePicture(currentlyDisplayed));
        }
    }
);

export const getRandomNaturePictures = () =>
    dispatch => axios.get('/api/v1/images/random')
        .then(response => dispatch(randomImageListLoaded(response.data)))
        .catch(error => console.log(error));

export const translationsLoaded = (otherTranslations) => ({
    type: TranslationAction.OTHER_TRANSLATIONS_LOADED,
    otherTranslations
});

export const loadOtherTranslations = () =>
    (dispatch, getState) => {
        if (getOtherTranslations(getState()).length > 0) {
            return;
        }
        axios.get('/api/v1/translations')
            .then(response => dispatch(translationsLoaded(response.data)))
            .catch(error => console.log(error));
    };

export const openEmailDialog = () => ({
    type: EmailAction.OPEN_DIALOG,
});

export const showEmailError = () => ({
    type: EmailAction.SHOW_EMAIL_ERROR,
});

export const hideEmailError = () => ({
    type: EmailAction.HIDE_EMAIL_ERROR,
});

export const subscribeToDailyVerse = (email, translation, translationLong) => (
    dispatch => {
        axios.post(`/api/v1/emails/${email}`,
            {
                translation
            }
        )
            .then((response) => {
                dispatch(hideEmailDialog());
                if (response.data.isSubscription) {
                    dispatch(showMessage(NotificationMessage.SUBSCRIPTION_SUCCESSFUL));
                } else {
                    dispatch(showMessage(NotificationMessage.TRANSLATION_SUCCESSFULLY_UPDATED + translationLong));
                }
            })
            .catch((error) => {
                console.log(error);
                dispatch(showMessage(NotificationMessage.SUBSCRIPTION_UNSUCCESSFUL));
            });
    }
);

export const processEmailDialogClose = (subscribed) => (
    (dispatch, getState) => {
        if (subscribed) {
            const state = getState();
            const email = getEmail(state);
            const translation = getTranslation(state);
            const translationLong = getLongTranslation(state);
            if (validateEmail(email)) {
                dispatch(hideEmailError());
                dispatch(subscribeToDailyVerse(email, translation, translationLong));
            } else {
                dispatch(showEmailError());
            }
        } else {
            dispatch(hideEmailDialog());
        }
    }
);

export const updateEmail = (email) => ({
    type: EmailAction.UPDATE_EMAIL,
    email,
});

export const hideEmailDialog = () => ({
    type: EmailAction.CLOSE_DIALOG,
});

export const openTranslationChooserDialog = () => ({
    type: TranslationAction.OPEN_DIALOG,
});

export const subscribeWebappUser = (endpoint) => ({
    type: WebappAction.SUBSCRIBE_USER,
    endpoint
});

export const updateWebappUser = (endpoint) => ({
    type: WebappAction.UPDATE_USER,
    endpoint
});

export const deleteWebappUser = () => ({
    type: WebappAction.DELETE_USER,
});

export const processChooseTranslationDialogClose = (update) => (
    (dispatch, getState) => {
        if (update) {
            const state = getState();
            dispatch(changeLanguage(getTranslation(state), getLongTranslation(state)));
        }
        dispatch(hideTranslationChooserDialog());
    }
);

export const hideTranslationChooserDialog = () => ({
    type: TranslationAction.CLOSE_DIALOG,
});

export const updateTranslation = (translation, translationLong) =>
    dispatch => {
        dispatch(updateWebappUserTranslation(translation));
        return dispatch(changeTranslation(translation, translationLong));
    };

export const changeTranslation = (translation, translationLong) => ({
    type: TranslationAction.CHANGE_TRANSLATION,
    translation,
    translationLong,
});

export const setCookie = (name, value, daysToExpire) => {
    const date = new Date();
    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));
    document.cookie = name + "=" + value + "; expires=" + date.toGMTString();
};

export const getCookie = name => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
};

export const unsubscribeFromEmail = (email) =>
    dispatch => axios.delete(`/api/v1/emails/${email}`)
        .then(() => {
            dispatch(closeUnsubscribeDialog());
            dispatch(showMessage(UnsubscribeDialogConst.UNSUBSCRIBED_SUCCESSFULLY))
        })
        .catch((error) => {
            console.log(error);
            dispatch(showMessage(UnsubscribeDialogConst.UNSUBSCRIBE_FAILED))
        });

export const openUnsubscribeDialog = () => ({
    type: UnsubscribeAction.OPEN_DIALOG,
});

export const closeUnsubscribeDialog = () => ({
    type: UnsubscribeAction.CLOSE_DIALOG,
});

export const processUnsubscribeDialogClose = (unsubscribed) => (
    (dispatch, getState) => {
        if (unsubscribed) {
            const state = getState();
            const email = getEmail(state);
            dispatch(unsubscribeFromEmail(email));
        } else {
            dispatch(closeUnsubscribeDialog());
        }
    }
);

export const insertWebappUserSubscription = (subscription) =>
    (dispatch, getState) => {
        dispatch(subscribeWebappUser(subscription.endpoint));
        let translation = getTranslation(getState());
        axios.post('/api/v1/webapp', {
            subscription,
            translation
        })
            .then((response) => {

            })
            .catch((error) => {
                console.log(error);
            });
    };

export const updateWebappUserSubscription = (subscription) =>
    (dispatch, getState) => {
        dispatch(updateWebappUser(subscription.endpoint));
        let translation = getTranslation(getState());
        axios.post('/api/v1/webapp', {
            subscription,
            translation
        })
            .then(() => {

            })
            .catch((error) => {
                console.log(error);
            });
    };

export const updateWebappUserTranslation = (translation) =>
    (dispatch, getState) => {
        axios.put('/api/v1/webapp', {
            endpoint: getWebappSubscription(getState()),
            translation
        })
            .then(() => {

            })
            .catch((error) => {
                console.log(error);
            });
    };
