import {connect} from 'react-redux';
import {changeTranslation} from "../../actions";
import TranslationChooser from "../presentational/TranslationChooser";
import {getLongTranslation, getOtherTranslations, getTranslation} from "../../selectors";

const mapStateToProps = state => ({
    translation: getTranslation(state),
    translationLong: getLongTranslation(state),
    otherTranslations: getOtherTranslations(state),
});

const mapDispatchToProps = dispatch => ({
    handleTranslationChange: (event) => {
        dispatch(changeTranslation(event.target.value, event.target.labels[0].childNodes[1].innerText));
    },
    handleOtherTranslationChange: (code, name) => dispatch(changeTranslation(code, name))
});

const TranslationChooserContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TranslationChooser);

export default TranslationChooserContainer;
