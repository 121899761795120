import React from 'react';
import PropTypes from 'prop-types';
import {InfoOutlined} from '@material-ui/icons';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ProgressiveImage from "react-progressive-image-loading";
import {VerseCardStyles} from "../../styles";
import {TooltipConst, VerseCardConst} from "../../constants";
import {getDateString} from "../../utils/Utils";

const VerseCard = (props) => {
    const {
        classes, verse, infoTooltip, hasImage,
        onInfoClick, onUpdateImage, onNoImage, imagesLoaded
    } = props;

    if (verse !== VerseCardConst.DEFAULT_VERSE && imagesLoaded) {
        if (verse.image === null) {
            onNoImage(verse.date);
        } else if (!hasImage) {
            onUpdateImage(verse.date);
        }
    }

    return (
        <Card raised>
            <Tooltip
                title={infoTooltip}
                placement="top-start"
                enterDelay={TooltipConst.ENTER_DELAY}
                leaveDelay={TooltipConst.LEAVE_DELAY}
            >
                <IconButton
                    className={classes.infoButton}
                    aria-label="Info"
                    onClick={() => onInfoClick(verse.imageSource)}
                >
                    <InfoOutlined/>
                </IconButton>
            </Tooltip>
            <CardActionArea className={classes.cardActionArea}>
                <div className={classes.cardImageWrapper}>
                    {
                        (hasImage)
                            ? (
                                <ProgressiveImage
                                    preview={require("../../img/dummy.png")}
                                    src={verse.image}
                                    render={(src, style) => (
                                        <img src={src} style={style} className={classes.cardImage} alt=""/>
                                    )}
                                />
                            ) : (
                                <img src={require("../../img/dummy.png")} className={classes.cardImagePlaceholder} alt=""/>
                            )
                    }
                </div>
                <CardContent>
                    <Typography paragraph variant="body1" className={classes.date}>
                        {getDateString(verse.date)}
                    </Typography>
                    <Typography paragraph variant="body1" className={classes.verse}>
                        {verse.content}
                    </Typography>
                    <Typography variant="body1" className={classes.reference}>
                        {verse.reference}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

VerseCard.defaultProps = {
    verse: VerseCardConst.DEFAULT_VERSE,
    hasImage: false,
};

VerseCard.propTypes = {
    classes: PropTypes.object.isRequired,
    verse: PropTypes.object,
    infoTooltip: PropTypes.string.isRequired,
    hasImage: PropTypes.bool,
    onInfoClick: PropTypes.func.isRequired,
    onUpdateImage: PropTypes.func.isRequired,
    onNoImage: PropTypes.func.isRequired,
    imagesLoaded: PropTypes.bool.isRequired,
};

export default withStyles(VerseCardStyles)(VerseCard);
