import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {GetApp} from '@material-ui/icons';
import Tooltip from "@material-ui/core/Tooltip";
import {AppStyle} from "./styles";
import VerseCardContainer from "./component/container/VerseCardContainer";
import CarouselContainer from "./component/container/CarouselContainer";
import Footer from "./component/presentational/Footer";
import FabEmailSubscriptionContainer from "./component/container/FabEmailSubscriptionContainer";
import FabTranslationContainer from "./component/container/FabTranslationContainer";
import EmailSubscriptionDialogContainer from "./component/container/EmailSubscriptionDialogContainer";
import MySnackbarContainer from "./component/container/MySnackbarContainer";
import ChooseTranslationDialogContainer from "./component/container/ChooseTranslationDialogContainer";
import {AppString, TooltipConst} from "./constants";
import UnsubscribeDialogContainer from "./component/container/UnsubscribeDialogContainer";

function App(props) {
    const {classes} = props;

    return (
        <div>
            <AppBar>
                <Toolbar>
                    <div className={classes.toolbar}>
                        <Typography variant="h6" color="inherit" className={classes.toolbarText}>
                            {AppString.TOOLBAR_TITLE}
                        </Typography>
                        <a href={AppString.DOWNLOAD_LINK} target="_blank" rel="noopener noreferrer">
                            <Tooltip title={AppString.DOWNLOAD_TOOLTIP}
                                     placement="bottom-end"
                                     enterDelay={TooltipConst.ENTER_DELAY}
                                     leaveDelay={TooltipConst.LEAVE_DELAY}>
                                <GetApp className={classes.downloadButton}/>
                            </Tooltip>
                        </a>
                    </div>
                </Toolbar>
            </AppBar>
            <CarouselContainer>
                <VerseCardContainer/>
            </CarouselContainer>
            <FabTranslationContainer/>
            <FabEmailSubscriptionContainer/>
            <EmailSubscriptionDialogContainer/>
            <ChooseTranslationDialogContainer/>
            <UnsubscribeDialogContainer/>
            <MySnackbarContainer/>
            <Footer/>
        </div>
    );
}

export default withStyles(AppStyle)(App);
