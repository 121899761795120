import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import {withStyles} from '@material-ui/core';
import {FabStyles} from '../../styles';
import {TooltipConst} from '../../constants';
import Fab from "@material-ui/core/Fab";

const FloatingActionButton = (props) => {
    const {
        classes, styleClass, color, size, tooltip, icon, onClick,
    } = props;

    return (
        <Tooltip
            title={tooltip}
            placement="left-start"
            enterDelay={TooltipConst.ENTER_DELAY}
            leaveDelay={TooltipConst.LEAVE_DELAY}
        >
            <Fab
                size={size}
                className={classes[styleClass]}
                color={color}
                onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }}
            >
                {icon}
            </Fab>
        </Tooltip>
    );
};

FloatingActionButton.defaultProps = {
    color: 'default',
    mini: false,
};

FloatingActionButton.propTypes = {
    classes: PropTypes.object.isRequired,
    styleClass: PropTypes.string.isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    tooltip: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default withStyles(FabStyles)(FloatingActionButton);
