import {connect} from 'react-redux';
import VerseCard from "../presentational/VerseCard";
import {insertRandomNaturePicture, updateImage} from "../../actions";
import {VerseCardConst} from "../../constants";
import {areImagesLoaded, getCurrentlyDisplayedVerseDate, getVerses} from "../../selectors";

const mapStateToProps = state => {
    let verse = undefined;
    let hasImage = false;
    let infoTooltip = VerseCardConst.INFO_TOOLTIP;
    const verses = getVerses(state);
    const currentlyDisplayed = getCurrentlyDisplayedVerseDate(state);
    const imagesLoaded = areImagesLoaded(state);
    if (Object.keys(verses).length > 0 && typeof verses[currentlyDisplayed] !== 'undefined') {
        verse = verses[currentlyDisplayed];
        hasImage = verses[currentlyDisplayed].hasImage;
        if (verses[currentlyDisplayed].randomImage) {
            infoTooltip = VerseCardConst.INFO_TOOLTIP_RANDOM;
        } else {
            infoTooltip = verses[currentlyDisplayed].imageSource.includes(VerseCardConst.PIXABAY)
                ? VerseCardConst.INFO_TOOLTIP_PIXABAY
                : VerseCardConst.INFO_TOOLTIP_PEXELS;
        }
    }
    return {
        verse,
        infoTooltip,
        hasImage,
        imagesLoaded,
    }
};

const mapDispatchToProps = dispatch => ({
    onInfoClick: (imageSource) => {
        let url = VerseCardConst.PEXELS_LINK;
        if (imageSource.includes(VerseCardConst.PIXABAY)) {
            url = VerseCardConst.PIXABAY_LINK;
        }
        window.open(url, '_blank')
    },
    onUpdateImage: (verseDate) => dispatch(updateImage(verseDate)),
    onNoImage: (verseDate) => dispatch(insertRandomNaturePicture(verseDate)),
});

const VerseCardContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VerseCard);

export default VerseCardContainer;
