import {combineReducers} from "redux";
import {
    EmailAction,
    MessageAction,
    TranslationAction,
    UnsubscribeAction,
    VerseAction,
    WebappAction
} from "./action-types";
import {getRandomNaturePictureIndex} from "./utils/Utils";
import {TranslationChooserConst} from "./constants";

const messageReducer = (state = {open: false}, action) => {
    switch (action.type) {
        case MessageAction.MESSAGE:
            return ({
                open: action.open,
                message: action.message,
            });
        default:
            return state;
    }
};

const emailSubscriptionReducer = (state = {
    open: false,
}, action) => {
    switch (action.type) {
        case EmailAction.OPEN_DIALOG:
            return {
                ...state,
                open: true,
            };
        case EmailAction.CLOSE_DIALOG:
            return {
                ...state,
                open: false,
            };
        case EmailAction.UPDATE_EMAIL:
            return {
                ...state,
                email: action.email,
            };
        case EmailAction.SHOW_EMAIL_ERROR:
            return {
                ...state,
                emailError: true,
            };
        case EmailAction.HIDE_EMAIL_ERROR:
            return {
                ...state,
                emailError: false,
            };
        default:
            return state;
    }
};

const webappSubscriptionReducer = (state = {}, action) => {
    switch (action.type) {
        case WebappAction.SUBSCRIBE_USER:
            return {
                ...state,
                endpoint: action.endpoint,
            };
        case WebappAction.UPDATE_USER:
            return {
                ...state,
                endpoint: action.endpoint,
            };
        case WebappAction.DELETE_USER:
            return {
                ...state,
                endpoint: null,
            };
        default:
            return state;
    }
};

const translationReducer = (state = {
    open: false,
    translation: TranslationChooserConst.GERMAN.value,
    translationLong: TranslationChooserConst.GERMAN.label,
    otherTranslation: false,
    otherTranslations: [],
}, action) => {
    switch (action.type) {
        case TranslationAction.OPEN_DIALOG:
            return {
                ...state,
                open: true,
            };
        case TranslationAction.CLOSE_DIALOG:
            return {
                ...state,
                open: false,
            };
        case TranslationAction.CHANGE_TRANSLATION:
            return {
                ...state,
                otherTranslation: action.otherTranslation,
                translation: action.translation,
                translationLong: action.translationLong,
            };
        case TranslationAction.OTHER_TRANSLATIONS_LOADED:
            return {
                ...state,
                otherTranslations: action.otherTranslations,
            };
        default:
            return state;
    }
};

const verseReducer = (state = {
    verses: {}, imagesLoaded: false, lang: 'de'
}, action) => {
    const versesCopy = {...state.verses};
    switch (action.type) {
        case VerseAction.ADD:
            const amount = action.verses.length;
            let i = 0;
            let latestDownloaded = null;
            action.verses.forEach(v => {
                i++;
                v.date = v.date.substring(0, 10);
                versesCopy[v.date] = v;
                versesCopy[v.date].hasImage = false;
                versesCopy[v.date].randomImage = false;
                if (i === amount) {
                    latestDownloaded = v.date;
                }
            });
            return {
                ...state,
                verses: versesCopy,
                latestDownloaded,
            };
        case VerseAction.SET_LATEST_DOWNLOADED_VERSE:
            return {
                ...state,
                latestDownloaded: action.date,
            };
        case VerseAction.SET_CURRENTLY_DISPLAYED:
            if (Object.keys(versesCopy).length > 0) {
                versesCopy[action.date].hasImage = false;
            }
            return {
                ...state,
                currentlyDisplayed: action.date,
                verses: versesCopy,
            };
        case VerseAction.UPDATE_IMAGE:
            versesCopy[action.date].hasImage = true;
            return {
                ...state,
                verses: versesCopy,
            };
        case VerseAction.INSERT_RANDOM_NATURE_PICTURE:
            const randomUrl = state.randomImageUrls.splice(
                getRandomNaturePictureIndex(state.randomImageUrls.length), 1)[0];
            const imageUrls = [...state.randomImageUrls];
            versesCopy[action.verseDate].image = randomUrl;
            versesCopy[action.verseDate].hasImage = true;
            versesCopy[action.verseDate].randomImage = true;

            return {
                ...state,
                verses: versesCopy,
                randomImageUrls: imageUrls,
                url: action.url,
            };
        case VerseAction.SET_RANDOM_IMAGE_LIST:
            return {
                ...state,
                imagesLoaded: true,
                randomImageUrls: action.urls,
            };
        case VerseAction.CHANGE_LANGUAGE:
            return {
                ...state,
                lang: action.lang,
                verses: {},
                latestDownloaded: undefined,
            };
        default:
            return state;
    }
};

export const unsubscribeReducer = (state = {open: false}, action) => {
    switch (action.type) {
        case UnsubscribeAction.OPEN_DIALOG:
            return {
                open: true,
            };
        case UnsubscribeAction.CLOSE_DIALOG:
            return {
                open: false,
            };
        default:
            return state;
    }
};

export const rootReducer = combineReducers({
    message: messageReducer,
    email: emailSubscriptionReducer,
    webapp: webappSubscriptionReducer,
    translation: translationReducer,
    verse: verseReducer,
    unsubscribe: unsubscribeReducer,
});
