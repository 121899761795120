import {connect} from 'react-redux';
import React from 'react';
import {Email} from '@material-ui/icons';
import FloatingActionButton from '../presentational/FloatingActionButton';
import {FloatingActionButtonConst} from '../../constants';
import {openEmailDialog} from "../../actions";

const mapStateToProps = state => ({
    tooltip: FloatingActionButtonConst.TOOLTIP_EMAIL,
    icon: <Email/>,
    styleClass: 'emailFab',
    color: 'secondary',
    size: 'large'
});

const mapDispatchToProps = dispatch => ({
    onClick: () => dispatch(openEmailDialog()),
});

const FabEmailSubscriptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FloatingActionButton);

export default FabEmailSubscriptionContainer;
