export const getVerses = state => state.verse.verses;
export const getCurrentlyDisplayedVerseDate = state => state.verse.currentlyDisplayed;
export const getLatestDownloadedVerse = state => state.verse.latestDownloaded;
export const areImagesLoaded = state => state.verse.imagesLoaded;
export const getCurrentLanguage = state => state.verse.lang;

export const isTranslationDialogOpen = state => state.translation.open;
export const getTranslation = state => state.translation.translation;
export const getLongTranslation = state => state.translation.translationLong;
export const getOtherTranslations = state => state.translation.otherTranslations;

export const isEmailDialogOpen = state => state.email.open;
export const getEmail = state => state.email.email;
export const getEmailError = state => state.email.emailError;

export const getWebappSubscription = state => state.webapp.endpoint;

export const isMessageDisplayed = state => state.message.open;
export const getMessage = state => state.message.message;

export const isUnsubscribeDialogOpen = state => state.unsubscribe.open;
