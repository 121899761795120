import {connect} from 'react-redux';
import {Translate} from '@material-ui/icons';
import React from 'react';
import FloatingActionButton from '../presentational/FloatingActionButton';
import {FloatingActionButtonConst} from '../../constants';
import {openTranslationChooserDialog} from "../../actions";

const mapStateToProps = state => ({
    tooltip: FloatingActionButtonConst.TOOLTIP_CHOOSE_TRANSLATION,
    icon: <Translate/>,
    styleClass: 'translationFab',
    size: 'small',
});

const mapDispatchToProps = (dispatch) => ({
    onClick: () => dispatch(openTranslationChooserDialog()),
});

const FabTranslationContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FloatingActionButton);

export default FabTranslationContainer;
