import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import {EmailSubscriptionDialogConst} from "../../constants";
import TranslationChooserContainer from "../container/TranslationChooserContainer";

const EmailSubscriptionDialog = (props) => {
    const {
        open, email,
        handleClose, handleEmailChange,
        fullScreen, emailError,
    } = props;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={(e) => {
                e.preventDefault();
                handleClose(false);
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{EmailSubscriptionDialogConst.TITLE}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {EmailSubscriptionDialogConst.EXPLANATION}
                </DialogContentText>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    handleClose(true);
                }}>
                    <TextField
                        value={email}
                        error={emailError}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        onChange={handleEmailChange}
                        fullWidth
                    />
                </form>
                <TranslationChooserContainer/>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose(false);
                    }}
                    color="primary">
                    {EmailSubscriptionDialogConst.CANCEL}
                </Button>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose(true);
                    }}
                    color="primary">
                    {EmailSubscriptionDialogConst.SUBSCRIBE}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

EmailSubscriptionDialog.defaultProps = {
    email: '',
    emailError: false
};

EmailSubscriptionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleEmailChange: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    emailError: PropTypes.bool,
};

export default withMobileDialog()(EmailSubscriptionDialog);
