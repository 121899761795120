import {connect} from 'react-redux';
import {processUnsubscribeDialogClose} from "../../actions";
import {getEmail, isUnsubscribeDialogOpen} from "../../selectors";
import UnsubscribeDialog from "../presentational/UnsubscribeDialog";

const mapStateToProps = state => ({
    open: isUnsubscribeDialogOpen(state),
    email: getEmail(state),
});

const mapDispatchToProps = dispatch => ({
    handleClose: unsubscribed => dispatch(processUnsubscribeDialogClose(unsubscribed)),
});

const UnsubscribeDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(UnsubscribeDialog);

export default UnsubscribeDialogContainer;
