import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const RootTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#DC2C01',
        },
    },
    typography: {
        useNextVariants: true,
    },
    overrides: {
        MuiInputBase: {
            root: {
                width: '100%',
            },
        },
    },
});

export const AppStyle = (theme) => ({
    toolbar: {
        width: '100%',
        textAlign: 'center',
    },
    toolbarText: {
        display: 'inline',
        margin: '0 1% 0 auto',
    },
    downloadButton: {
        color: 'white',
        verticalAlign: 'middle',
        margin: '0 auto 4px 0',
    },
});

export const VerseCardStyles = (theme) => ({
    cardActionArea: {
        width: '100%',
    },
    cardImageWrapper: {
        margin: '0 auto',
        width: '100%',
        paddingTop: '56%',
        position: 'relative',
        overflow: 'hidden',
    },
    cardImage: {
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
    },
    cardImagePlaceholder: {
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        filter: "blur(10px)",
    },
    date: {
        textAlign: 'right',
        color: '#808080 !important',
    },
    reference: {
        color: '#512da8 !important',
    },
    infoButton: {
        position: 'fixed',
        verticalAlign: 'top',
        top: '0px',
        right: '13%',
        margin: '0',
        zIndex: '10',
        color: 'lightgray',
        padding: theme.spacing(1),
    },
});

export const CarouselStyles = (theme) => ({
    contentGrid: {
        width: '100%',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -48%)',
        height: theme.spacing(60),
        maxWidth: theme.spacing(80),
    },
    fullHeight: {
        height: '100%',
        maxHeight: theme.spacing(60),
    },
    leftGrid: {
        margin: 'auto',
        textAlign: 'right',
        padding: '0 !important',
    },
    rightGrid: {
        margin: 'auto',
        textAlign: 'left',
        padding: '0 !important',
    },
    navigationButton: {
        padding: theme.spacing(0.5),
    }
});

export const FooterStyle = (theme) => ({
    footer: {
        backgroundColor: '#555555',
        position: 'fixed',
        left: '0',
        bottom: '0',
        width: '100%',
        height: theme.spacing(11),
    },
    footerWrapper: {
        padding: '16px',
        width: '80%',
        maxWidth: '550px',
        margin: '0 auto 0',
        display: 'flex',
    },
    footerLinks: {
        margin: '0 2% 0 auto',
    },
    footerImages: {
        margin: '0 auto 0 2%',
        display: 'grid',
    },
    footerImage: {
        marginTop: theme.spacing(0.25),
        marginBottom: theme.spacing(0.25),
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5),
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    footerElement: {
        textAlign: 'center',
        padding: '4px',
        color: 'white',
    },
    footerPrivacy: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
});

export const FabStyles = (theme) => ({
    emailFab: {
        position: 'fixed',
        bottom: '0%',
        marginBottom: theme.spacing(7.5),
        right: '5%',
        zIndex: '99',
    },
    translationFab: {
        position: 'fixed',
        bottom: '0%',
        marginBottom: theme.spacing(16),
        marginRight: theme.spacing(1),
        right: '5%',
        zIndex: '99',
    },
});

export const TranslationChooserStyles = (theme) => ({
    translationFormControl: {
        marginTop: theme.spacing(3),
    },
    otherTranslationsFormControl: {
        margin: theme.spacing,
        minWidth: theme.spacing(30),
    },
    radioButtonGroup: {
        margin: `${theme.spacing(1)}px 0`,
    },
    radioButton: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    otherTranslationVisible: {
        display: 'block',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: theme.spacing(30),
    },
    otherTranslationInvisible: {
        display: 'none',
    },
    otherTranslationTextVisible: {
        display: 'block',
        width: '100%',
    },
    otherTranslationTextInvisible: {
        display: 'none',
    },
    translationListSection: {
        backgroundColor: 'inherit',
    },
    translationListItem: {
        backgroundColor: 'inherit',
        padding: 0,
    },
});

export const MySnackbarStyles = (theme) => ({
    snackbar: {
        zIndex: 1000,
        marginTop: theme.spacing(7),
    },
});
