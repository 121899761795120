import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import {applyMiddleware, createStore} from "redux";
import {composeWithDevTools} from 'redux-devtools-extension';
import {Provider} from "react-redux";
import thunkMiddleware from 'redux-thunk';
import {ThemeProvider as MuiThemeProvider} from '@material-ui/core/styles';
import {RootTheme} from "./styles";
import {rootReducer} from "./reducers";
import App from './App';
import {checkCookiesForTranslation, loadInitialVerses, loadRandomNaturePictures, openSpecificPage} from "./utils/Utils";
import {insertWebappUserSubscription, updateWebappUserSubscription} from "./actions";

document.body.style.margin = '0';
document.body.style.backgroundColor = '#f5f5f5';

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(
            thunkMiddleware,
        ),
    ),
);

checkCookiesForTranslation(store);
loadRandomNaturePictures(store);
loadInitialVerses(store);
openSpecificPage(store);

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={RootTheme}>
            <App />
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
);

if (window.location.href.includes('oemel09')) {
    console.log('Running on dailypower.oemel09.de');
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('./dailypower-service-worker.js', {scope: './'})
                .then(registration => onServiceWorkerRegistered(registration))
                .catch(err => console.error("Service Worker Failed to Register", err));
        });
    } else {
        console.log('Service Worker is not supported by browser.');
    }
} else {
    console.log('Running on dailypower.de');
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
}

function onServiceWorkerRegistered(registration) {
    upsertWebappUserPushSubscription(registration);
}

function upsertWebappUserPushSubscription(serviceWorker) {
    serviceWorker.pushManager.getSubscription()
        .then(subscription => {
            let isSubscribed = !(subscription === null);
            if (isSubscribed) {
                store.dispatch(updateWebappUserSubscription(subscription));
            } else {
                if (shouldAskForNotificationPermission()) {
                    window.Notification.requestPermission()
                        .then(permission => {
                            if (permission !== 'granted') {
                                console.error('Permission not granted for Notification');
                            } else {
                                registerWebappUserForPushNotifications(serviceWorker);
                            }
                        });
                } else if (isPermissionGranted()) {
                    registerWebappUserForPushNotifications(serviceWorker);
                }
            }
        })
        .catch(error => console.error(error));
}

function registerWebappUserForPushNotifications(serviceWorker) {
    const applicationServerKey = urlB64ToUint8Array('BMleJS0bGxWRdOnWbFX1s-F2aN8HlerlWEK9C93VJ64cNDk4Erz3YiUZhL2z1JeGNUG3fBX4Vzj1YwE2H52oCAI');
    const options = {applicationServerKey, userVisibleOnly: true};
    return serviceWorker.pushManager.subscribe(options)
        .then(subscription => {
            store.dispatch(insertWebappUserSubscription(subscription));
        })
        .catch(error => console.error(error));
}

const urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
};

function shouldAskForNotificationPermission() {
    return Notification.permission === 'default';
}

function isPermissionGranted() {
    return Notification.permission === 'granted';
}
