import {connect} from 'react-redux';
import {processChooseTranslationDialogClose} from "../../actions";
import ChooseTranslationDialog from "../presentational/ChooseTranslationDialog";
import {isTranslationDialogOpen} from "../../selectors";

const mapStateToProps = state => ({
    open: isTranslationDialogOpen(state),
});

const mapDispatchToProps = dispatch => ({
    handleClose: (update) => dispatch(processChooseTranslationDialogClose(update))
});

const ChooseTranslationDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ChooseTranslationDialog);

export default ChooseTranslationDialogContainer;
