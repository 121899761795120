import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import {TranslationChooserConst} from "../../constants";
import TranslationChooserContainer from "../container/TranslationChooserContainer";

const ChooseTranslationDialog = (props) => {
    const {
        open, handleClose, fullScreen,
    } = props;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={(e) => {
                e.preventDefault();
                handleClose(false);
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">{TranslationChooserConst.TITLE}</DialogTitle>
            <DialogContent>
                <TranslationChooserContainer/>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose(false);
                    }}
                    color="primary">
                    {TranslationChooserConst.CANCEL}
                </Button>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose(true);
                    }}
                    color="primary">
                    {TranslationChooserConst.SET_TRANSLATION}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ChooseTranslationDialog.defaultProps = {
    email: '',
    emailError: false
};

ChooseTranslationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
};

export default withMobileDialog()(ChooseTranslationDialog);
