import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from "@material-ui/core/Slide";
import {SnackbarConst} from '../../constants';
import {MySnackbarStyles} from '../../styles';

const transitionDown = (props) => {
    return <Slide {...props} direction="down" />;
};

const MySnackbar = (props) => {
    const {
        classes, open, handleClose, message,
    } = props;

    return (
        <Snackbar
            className={classes.snackbar}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={SnackbarConst.AUTO_HIDE_DURATION}
            onClose={handleClose}
            TransitionComponent={transitionDown}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={(
                <span id="message-id">
          {message}
        </span>
            )}
        />
    );
};

MySnackbar.defaultProps = {
    message: '',
};

MySnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    message: PropTypes.string,
};

export default withStyles(MySnackbarStyles)(MySnackbar);
