import {connect} from 'react-redux';
import MySnackbar from '../presentational/MySnackbar';
import {hideMessage} from '../../actions';
import {getMessage, isMessageDisplayed} from "../../selectors";

const mapStateToProps = state => ({
    open: isMessageDisplayed(state),
    message: getMessage(state),
});

const mapDispatchToProps = dispatch => ({
    handleClose: () => {
        dispatch(hideMessage());
    },
});

const MySnackbarContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MySnackbar);

export default MySnackbarContainer;
