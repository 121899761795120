export const AppString = {
    APP_CREATOR: 'oemel09',
    TOOLBAR_TITLE: 'Daily Power - Täglicher Bibelvers',
    DOWNLOAD_TOOLTIP: 'Download the app on Google Play',
    DOWNLOAD_LINK: 'https://play.google.com/store/apps/details?id=de.oemel09.dailypower',
};

export const VerseCardConst = {
    DEFAULT_VERSE: {
        date: 'yyyy-MM-dd',
        content: 'The daily verse should appear here. Looks like we ran into some issue here...',
        reference: AppString.APP_CREATOR,
        image: '',
    },
    INFO_TOOLTIP_RANDOM: 'This image is randomly chosen from pixabay.com',
    INFO_TOOLTIP_PIXABAY: 'This image was selected on pixabay.com',
    PIXABAY_LINK: 'https://pixabay.com',
    INFO_TOOLTIP_PEXELS: 'This image was selected on pexels.com',
    PEXELS_LINK: 'https://www.pexels.com',
    PIXABAY: 'pixabay'
};

export const TooltipConst = {
    ENTER_DELAY: 200,
    LEAVE_DELAY: 200,
};

export const FloatingActionButtonConst = {
    TOOLTIP_EMAIL: 'Get the daily verse by email',
    TOOLTIP_CHOOSE_TRANSLATION: 'Choose another translation',
};

export const EmailSubscriptionDialogConst = {
    TITLE: 'Get the daily verse by email',
    EXPLANATION: 'Enter your email and choose your favorite translation to sign up.',
    CANCEL: 'Cancel',
    SUBSCRIBE: 'Subscribe',
};

export const TranslationChooserConst = {
    TITLE: 'Choose a translation',
    TRANSLATION: 'Choose your favorite translation',
    OTHER_TRANSLATION: 'Other translation',
    GERMAN: {
        label: 'German  (Various chosen translations)',
        value: 'de',
    },
    BASIC_ENGLISH_BIBLE: {
        label: 'English (Basic English Bible)',
        value: 'basicenglish',
    },
    SPANISH: {
        label: 'Spanish (Reina Valera)',
        value: 'valera',
    },
    THAI: {
        label: 'Thai (from KJV)',
        value: 'thai',
    },
    OTHER: {
        label: 'I want another translation',
        value: 'tbd',
    },
    CANCEL: 'Cancel',
    SET_TRANSLATION: 'Set translation',
};

export const DEFAULT_TRANSLATION_VALUES = [
    TranslationChooserConst.GERMAN.value,
    TranslationChooserConst.BASIC_ENGLISH_BIBLE.value,
    TranslationChooserConst.SPANISH.value,
    TranslationChooserConst.THAI.value,
];

export const SnackbarConst = {
    AUTO_HIDE_DURATION: 3000,
};

export const NotificationMessage = {
    SUBSCRIPTION_SUCCESSFUL: 'Successfully subscribed to the daily verse.',
    SUBSCRIPTION_UNSUCCESSFUL: 'Failed to subscribe to the daily verse.',
    TRANSLATION_SUCCESSFULLY_UPDATED: 'Your translation was successfully updated to ',
};

export const FooterConst = {
    links: {
        TWITTER: 'https://twitter.com/oemel09/',
        GITHUB: 'https://github.com/oemel09/',
        GOOGLE_PLAY: 'https://play.google.com/store/apps/dev?id=6053418656861582677',
        EMAIL: 'mailto:hallo@oemel09.de',
        STACKOVERFLOW: 'https://stackoverflow.com/users/10827064/oemel09',
        SPOTIFY: 'https://open.spotify.com/user/oemel09',
    },
    imageAltText: {
        TWITTER: 'twitter',
        GITHUB: 'github',
        GOOGLE_PLAY: 'google play',
        EMAIL: 'email me',
        STACKOVERFLOW: 'stackoverflow',
        SPOTIFY: 'spotify',
    },
};

export const CookieConst = {
    TRANSLATION: 'translation',
    TRANSLATION_LONG: 'translationName',
    LATEST_VERSE_REQUEST_DATE: 'latestVerseRequestDate'
};

export const UnsubscribeDialogConst = {
    UNSUBSCRIBE_DIALOG_TEXT: 'Do you really want to unsubscribe?',
    NO: 'No',
    YES: 'Yes',
    UNSUBSCRIBED_SUCCESSFULLY: 'You unsubscribed successfully!',
    UNSUBSCRIBE_FAILED: 'An error occurred while unsubscribing.',
};

export const VerseRequestConst = {
    VERSE_REQUEST_LIMIT: 10,
    VERSE_REQUEST_FUTURE_VERSES: 5,
};
