import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {UnsubscribeDialogConst} from "../../constants";

const UnsubscribeDialog = (props) => {
    const {open, email, handleClose} = props;
    return (
        <Dialog
            open={open}
            onClose={(e) => {
                e.preventDefault();
                handleClose(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {email}
                </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {UnsubscribeDialogConst.UNSUBSCRIBE_DIALOG_TEXT}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose(false);
                    }}
                    color="primary"
                >
                    {UnsubscribeDialogConst.NO}
                </Button>
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        handleClose(true);
                    }}
                    color="primary" autoFocus
                >
                    {UnsubscribeDialogConst.YES}
                </Button>
            </DialogActions>
        </Dialog>);
};

UnsubscribeDialog.defaultProps = {
    email: '',
};

UnsubscribeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    email: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
};

export default UnsubscribeDialog;
