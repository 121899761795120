import {connect} from 'react-redux';
import {processEmailDialogClose, updateEmail} from "../../actions";
import EmailSubscriptionDialog from "../presentational/EmailSubscriptionDialog";
import {getEmail, getEmailError, isEmailDialogOpen} from "../../selectors";

const mapStateToProps = state => ({
    open: isEmailDialogOpen(state),
    email: getEmail(state),
    emailError: getEmailError(state),
});

const mapDispatchToProps = dispatch => ({
    handleClose: (subscribed) => dispatch(processEmailDialogClose(subscribed)),
    handleEmailChange: (event) => dispatch(updateEmail(event.target.value)),
});

const EmailSubscriptionDialogContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailSubscriptionDialog);

export default EmailSubscriptionDialogContainer;
