import {
    changeTranslation,
    getCookie,
    getRandomNaturePictures,
    getVersesAsync,
    loadOtherTranslations,
    openEmailDialog,
    openUnsubscribeDialog,
    setCurrentlyDisplayedVerse,
    setLanguage,
    updateEmail
} from "../actions";
import {CookieConst, VerseRequestConst} from "../constants";

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

const leadingZero = n => (
    (n < 10) ? ("0" + n) : n
);

export const getDbDate = date => (
    date.getFullYear() + "-" + leadingZero(date.getMonth() + 1) + "-" + leadingZero(date.getDate())
);

export const getDateObject = dateString => {
    return new Date(dateString + ' 00:00');
};

export const getDateString = date => {
    const part = date.split("-");
    return part[2] + "." + part[1] + "." + part[0];
};

export const addDays = (date, days) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

export const increaseDate = (dateString) => {
    return getDbDate(addDays(dateString + ' 00:00', 1));
};

export const decreaseDate = (dateString) => {
    return getDbDate(addDays(dateString + ' 00:00', -1));
};

export const nextDayAvailable = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return new Date(date + ' 00:00').getTime() < today.getTime();
};

export const shouldLoadNewVerses = (currentlyDisplayed, latestDownloaded) => {
    const timeDiff = getDateObject(currentlyDisplayed).getTime() - getDateObject(latestDownloaded).getTime();
    return timeDiff < 1000 * 60 * 60 * 24 * 4;
};

export const getRandomNaturePictureIndex = (max) => {
    const min = 1;
    return Math.random() * (max - min) + min;
};

export const checkCookiesForTranslation = (store) => {
    const cookieTranslation = getCookie(CookieConst.TRANSLATION);
    if (cookieTranslation) {
        store.dispatch(setLanguage(cookieTranslation));
        const cookieTranslationLong = getCookie(CookieConst.TRANSLATION_LONG);
        store.dispatch(changeTranslation(cookieTranslation, cookieTranslationLong));
    } else {
        if (!navigator.language.startsWith('de')) {
            store.dispatch(setLanguage('basicenglish'));
        }
    }
    store.dispatch(loadOtherTranslations());
};

export const loadRandomNaturePictures = (store) => {
    store.dispatch(getRandomNaturePictures());
};

export const loadInitialVerses = (store) => {
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const lastLoadedDate = getCookie(CookieConst.LATEST_VERSE_REQUEST_DATE);
    let futureDate;
    if (lastLoadedDate == null) {
        futureDate = addDays(currentDate, VerseRequestConst.VERSE_REQUEST_FUTURE_VERSES);
    } else {
        futureDate = new Date(lastLoadedDate);
        let newFutureDate = addDays(currentDate, 1);
        if (futureDate < newFutureDate) {
            while (futureDate < newFutureDate) {
                futureDate = addDays(futureDate, VerseRequestConst.VERSE_REQUEST_LIMIT);
            }
        }
    }
    store.dispatch(getVersesAsync(futureDate));
    store.dispatch(setCurrentlyDisplayedVerse(getDbDate(currentDate)));
};

export const openSpecificPage = (store) => {
    const url = new URL(window.location.href);
    const path = url.pathname.split('/api/v1/emails/')[1];
    const email = url.searchParams.get('email');
    if (path === 'change') {
        store.dispatch(updateEmail(email));
        store.dispatch(openEmailDialog());
    } else if (path === 'delete') {
        store.dispatch(updateEmail(email));
        store.dispatch(openUnsubscribeDialog());
    }
};
