import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {TranslationChooserStyles} from "../../styles";
import {DEFAULT_TRANSLATION_VALUES, TranslationChooserConst} from "../../constants";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

const translationComparator = (a, b) => ((a.language === b.language) ? a.name > b.name : a.language > b.language);

const getTranslationList = (classes, otherTranslations, handleOtherTranslationChange) => {
    otherTranslations.sort((a, b) => translationComparator(a, b));
    const translationList = [];
    let lastLanguage = null;
    for (let i = 0; i < otherTranslations.length; i++) {
        const language = otherTranslations[i].language;
        if (lastLanguage === language) continue;
        lastLanguage = language;
        translationList.push(
            <li key={language} className={classes.translationListSection}>
                <ul className={classes.translationListItem}>
                    <ListSubheader>{language}</ListSubheader>
                    {
                        otherTranslations.map((t) => {
                            if (language !== t.language) return null;
                            return (
                                <ListItem
                                    key={t.code}
                                    button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleOtherTranslationChange(t.code, t.name);
                                    }}
                                >
                                    <ListItemText primary={t.name}/>
                                </ListItem>
                            );
                        })
                    }
                </ul>
            </li>
        );
    }
    return translationList;
};

const TranslationChooser = (props) => {
    const {
        classes, translation, translationLong, otherTranslations,
        handleTranslationChange, handleOtherTranslationChange,
    } = props;
    const checkOtherTranslation = !DEFAULT_TRANSLATION_VALUES.some(t => t === translation);
    const translationValue = 'translationValue';

    return (
        <div>
            <FormControl component="fieldset" className={classes.translationFormControl}>
                <FormLabel component="legend">{TranslationChooserConst.TRANSLATION}</FormLabel>
                <RadioGroup
                    className={classes.radioButtonGroup}
                    aria-label={TranslationChooserConst.TRANSLATION}
                    name="gender1"
                    value={translation}
                    onChange={handleTranslationChange}
                >
                    <FormControlLabel
                        value={TranslationChooserConst.GERMAN.value}
                        control={<Radio color="primary" className={classes.radioButton}/>}
                        label={TranslationChooserConst.GERMAN.label}
                    />
                    <FormControlLabel
                        value={TranslationChooserConst.BASIC_ENGLISH_BIBLE.value}
                        control={<Radio color="primary" className={classes.radioButton}/>}
                        label={TranslationChooserConst.BASIC_ENGLISH_BIBLE.label}
                    />
                    <FormControlLabel
                        value={TranslationChooserConst.SPANISH.value}
                        control={<Radio color="primary" className={classes.radioButton}/>}
                        label={TranslationChooserConst.SPANISH.label}
                    />
                    <FormControlLabel
                        value={TranslationChooserConst.THAI.value}
                        control={<Radio color="primary" className={classes.radioButton}/>}
                        label={TranslationChooserConst.THAI.label}
                    />
                    <FormControlLabel
                        value={TranslationChooserConst.OTHER.value}
                        control={
                            <Radio
                                color="primary"
                                className={classes.radioButton}
                                checked={checkOtherTranslation}
                            />}
                        label={TranslationChooserConst.OTHER.label}
                    />
                </RadioGroup>
            </FormControl>
            <List
                className={classes[(checkOtherTranslation && translation === TranslationChooserConst.OTHER.value)
                    ? 'otherTranslationVisible'
                    : 'otherTranslationInvisible']}
                subheader={<li/>}
            >
                {getTranslationList(classes, otherTranslations, handleOtherTranslationChange)}
            </List>
            <FormControl
                className={classes[(checkOtherTranslation && translation !== TranslationChooserConst.OTHER.value)
                    ? 'otherTranslationTextVisible'
                    : 'otherTranslationTextInvisible']}
            >
                <InputLabel htmlFor="name-readonly">
                    {TranslationChooserConst.OTHER_TRANSLATION}
                </InputLabel>
                <Select
                    value={translationValue}
                    onClick={(e) => {
                        e.preventDefault();
                        handleOtherTranslationChange(TranslationChooserConst.OTHER.value, TranslationChooserConst.OTHER.label);
                    }}
                    input={<Input name="name" id="name-readonly" readOnly/>}
                >
                    <MenuItem value={translationValue}>{translationLong}</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
};

TranslationChooser.defaultProps = {
    translationLong: TranslationChooserConst.OTHER.label,
};

TranslationChooser.propTypes = {
    classes: PropTypes.object.isRequired,
    translation: PropTypes.string.isRequired,
    translationLong: PropTypes.string,
    otherTranslations: PropTypes.array,
    handleTranslationChange: PropTypes.func.isRequired,
    handleOtherTranslationChange: PropTypes.func.isRequired,
};

export default withStyles(TranslationChooserStyles)(TranslationChooser);
