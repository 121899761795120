import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackIos, ArrowForwardIos} from "@material-ui/icons";
import {CarouselStyles} from "../../styles";
import {nextDayAvailable} from "../../utils/Utils";
import {Swipeable} from "react-swipeable";

const VerseCard = (props) => {
    const {classes, children, currentlyDisplayed, increase, decrease, latestDownloaded} = props;

    return (
        <div className={classes.contentGrid}>
            <Grid container spacing={2} className={classes.fullHeight}>
                <Grid item xs className={classes.leftGrid}>
                    <IconButton
                        className={classes.navigationButton}
                        color="secondary"
                        onClick={e => {
                            e.preventDefault();
                            decrease(currentlyDisplayed, latestDownloaded);
                        }}
                    >
                        <ArrowBackIos />
                    </IconButton>
                </Grid>
                <Grid item xs={9} className={classes.fullHeight}>
                    <Swipeable
                        onSwipedLeft={() => increase()}
                        onSwipedRight={() => decrease(currentlyDisplayed, latestDownloaded)}
                    >
                        {children}
                    </Swipeable>
                </Grid>
                <Grid item xs className={classes.rightGrid}>
                    <IconButton
                        disabled={(!nextDayAvailable(currentlyDisplayed))}
                        className={classes.navigationButton}
                        color={"secondary"}
                        onClick={e => {
                            e.preventDefault();
                            increase();
                        }}
                    >
                        <ArrowForwardIos />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    );
};

VerseCard.defaultProps = {
    latestDownloaded: '',
};

VerseCard.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    currentlyDisplayed: PropTypes.string.isRequired,
    increase: PropTypes.func.isRequired,
    decrease: PropTypes.func.isRequired,
    latestDownloaded: PropTypes.string,
};

export default withStyles(CarouselStyles)(VerseCard);
